<template>
<div>
    <div class="card">
        <div id="slideshow">
            <div><img src="@/assets/bg1.jpg"/></div>
            <div><img src="@/assets/bg2.jpg"/></div>
            <div><img src="@/assets/bg3.jpg"/></div>
        </div>
        
       <!-- <h3>Startup name here</h3> -->
    </div>
   
</div>
</template>
<script>
export default {
    name:"ImageChangingCard",
    mounted(){
    
        this.$nextTick(()=>
        {
        global.jQuery("#slideshow > div:gt(0)").hide();
        setInterval(function() { 
        global.jQuery('#slideshow > div:first')
            .fadeOut(1500)
            .next()
            .fadeIn(1500)
            .end()
            .appendTo('#slideshow');
        },  10000);})
        }
        
    }
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Reggae+One&display=swap');
h3{
    font-family: 'Reggae One', cursive;
    color:white;
}
.card{
    margin:auto;
    height:400px;
    border-radius: 20px;
    margin-bottom:15px;
    margin-top:10px;
    width:100%;
    box-shadow: 5px 5px 30px 7px rgba(0,0,0,0.25), -5px -5px 30px 7px rgba(0,0,0,0.22);
}
#slideshow{
    display:inherit;
}
#slideshow img{
    position:absolute;
    width:100%;
    height:400px;
    border-radius: 20px;
    object-fit: cover;
    opacity:0.8;
}
.btn{
    width: 100px;
    margin: auto;
    margin-bottom:40px;
    z-index: 3;
}
</style>